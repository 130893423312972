import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Pagination, PaginationItem } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    selected: {
        color: 'white !important',
        backgroundColor: `${theme.palette.primary.main} !important`
    }
}))

export default function PaginationComponent({
    page = 1, count = 0, variant = 'rounded',
    onPageChange
}) {
    const classes = useStyles()
    return (
        <Pagination count={count} page={page} variant={variant} onChange={onPageChange} siblingCount={0}
            renderItem={(item) => <PaginationItem {...item} classes={{ selected: classes.selected }} />}
        />
    )
}
