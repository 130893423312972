import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import PortfolioCard from './PortfolioCard'
import Routes from '../../utilities/Routes'
import RightArrow from '../SVGs/RightArrow'
import LinkButton from '../GeneralComponents/Buttons/LinkButton'

const useStyles = makeStyles(theme => ({
    gridItem: {
        padding: "28px 0px",
        [theme.breakpoints.up('sm')]: {
            padding: "60px 0px"
        }
    },
    gridItem2: {
        flex: 1,
        padding: "28px 10px",
        [theme.breakpoints.up('sm')]: {
            padding: "60px 20px"
        }
    }
}))

export default function SolutionCards({ cardList = [], showViewAllButton = false }) {
    const classes = useStyles()
    return (
        <div>
            <Grid container spacing={5} alignItems='stretch'>
                {
                    cardList.map((item, index) => {
                        return <Grid item key={item.id} xs={12} sm={6} md={4} >
                            <PortfolioCard data={item} />
                        </Grid>
                    })
                }
                {
                    showViewAllButton &&
                    <Grid item xs={12}>
                        <Grid container justifyContent='center'>
                            <Grid item>
                                <LinkButton className={classes.buttonStyles} href={Routes.portfolio}>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item>
                                            View All Projects
                                        </Grid>
                                        <Grid item style={{ display: 'flex' }}>
                                            <RightArrow />
                                        </Grid>
                                    </Grid>
                                </LinkButton>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </div>
    )
}
